import { createSelector } from 'reselect'
import { safeget } from 'utils'


export const getUserCompetitions = createSelector(
    state => state.competition.competitions,
    state => state.entities.competitions,
    state => state.entities.fantasyTeams,
    state => state.entities.users,
    (competitions, competitionEntities,fantasyTeamEntities, userEntities) => {
        

        var el = competitions || { isFetching : false, didInvalidate : false, items : []}



        const items =  (el.items || []).map(item => {
            const competition = competitionEntities[item.competition];
            const owner = competition.owner && userEntities[competition.owner]
            
            const teams = item.teams.map(t => fantasyTeamEntities[t + '']).filter(t => t);

            return {
                ...item,
                competition : {
                    ...competition,
                    owner
                },
                teams
            }
        });

        return {
            ...competitions,
            items
        }
    }
)


export const getCompetitionsSearchResult = createSelector(
    state => state.competition.search,
    state => state.entities.competitions,
    state => state.entities.users,
    (search, competitionEntities, userEntities) => {
        return {
            ...search,
            items : (search.items || []).map(item => {
                const competition = competitionEntities[item.competition];
                const owner = competition.owner && userEntities[competition.owner]

                return {
                    ...competition,
                    owner
                };
            })
        }
    }
)


export const getStandings = createSelector(
    state => state.competition.standings,
    state => state.entities.leaderboards,
    (standings, leaderboardEntities) => {

        const items =  (standings && standings.items || []).map(item => {
            return leaderboardEntities[item];
        });

        return {
            ...standings,
            items
        }
    }
)



export const getQualifications = createSelector(
    state => state.competition.qualifications,
    state => state.entities.cupQualifications,
    (qualifications, cupQualificationEntities) => {

        const items =  (qualifications && qualifications.items || []).map(item => {
            return cupQualificationEntities[item];
        });

        return {
            ...qualifications,
            items
        }
    }
)


export const getCompetitionInvitations =  createSelector(
    (state) => { return state.competition.invitations },
    (state) => { return state.entities.invitations },
    (state) => { return state.entities.users },
    (state) => { return state.entities.competitions },
    (invitations, invitationEntities, userEntities, competitionEntities) => {
        const items = (invitations && invitations.items || []).map(id => {
            const invitation = invitationEntities[id];
            const sender = userEntities[invitation.sender];
            const competition = competitionEntities[invitation.competition];
            
            return {
                ...invitation,
                sender,
                competition
            }
        });

        return {
            ...invitations,
            items
        };
    
    }
)


export const makeGetEnrollmentOptions = () => createSelector(
    (state, competitionId) => safeget(state.competition.byCompetition, [competitionId, 'enrollmentOptions']),
    state => state.entities.fantasyTeams,
    (enrollmentOptions, fantasyTeamEntities) => {
        const { item, isFetching } = enrollmentOptions || { isFetching : false, item: null }
      
        return {
            isFetching,
            item : {
                ...item,
                fantasyTeams : (item && item.fantasyTeams || []).map(ft => {
                    return fantasyTeamEntities[ft]
                })      
            }      
        }

    }
)



export const makeGetMyEnrollments = () => createSelector(
    (state, competitionId) => safeget(state.competition.byCompetition, [competitionId, 'myEnrollments']),
    state => state.entities.enrollments,
    state => state.entities.fantasyTeams,
    state => state.entities.users,
    (myEnrollments, enrollmentEntities, fantasyTeamEntities, userEntities) => {
        return {
            ...myEnrollments,
            items : myEnrollments && myEnrollments.items && myEnrollments.items.map(id => {
                const enrollment = enrollmentEntities[id];
                const fantasyTeam = fantasyTeamEntities[enrollment.fantasyTeam];
                const user = userEntities[fantasyTeam.user];

                return {
                    ...enrollment,
                    fantasyTeam : {
                        ...fantasyTeam,
                        user
                    }
                }
            })
        }
    }
)


export const makeGetCompetition = () => createSelector(
    (state, id) => { return state.entities.competitions[id] },
    (state) =>  state.entities.users,
    (state) =>  state.entities.games,
    (competition, userEntities, gameEntities) => {
        const owner = competition && competition.owner && userEntities[competition.owner];
        const game = competition && gameEntities[competition.game];

        
        return {
            ...competition,
            game,
            owner
        }
    }
)




export const getCompetition = createSelector(
    state => state.competition.competition,
    state => state.entities.competitions,
    (competition, competitionEntities) => {
        

        return {
            ...competition,
            item : competitionEntities[competition.item]
        }
    }
)


export const makeGetEnrollments = () => createSelector(
    (state, competitionId) => safeget(state.competition.byCompetition, [competitionId, 'enrollments']),
    state => state.entities.enrollments,
    state => state.entities.fantasyTeams,
    state => state.entities.users,
    (competitionEnrollments, enrollmentEntities, fantasyTeamEntities, userEntities) => {
        const { item } = competitionEnrollments || { item : { items : [] } };
        const { items } = item || { items : [] };

        return {
            ...competitionEnrollments, 
            item : {
                ...item,
                items : items.map(id => {
                    const enrollment = enrollmentEntities[id];
                    const fantasyTeam = fantasyTeamEntities[enrollment.fantasyTeam];
                    const user = userEntities[fantasyTeam.user];
                    
                    return {
                        ...enrollment,
                        fantasyTeam : {
                            ...fantasyTeam,
                            user
                        }
                    }
                })
            }
        };
    }
)


export const getPrevious = createSelector(
    state => state.competition.previous,
    state => state.entities.competitions,
    state => state.entities.games,
    (previous, competitionEntities, gameEntities) => {

        return {
            ...previous,
            item : {
                ...previous.item,
                items : (previous.item && previous.item.items || []).map(id => {
                    const competition = competitionEntities[id] ;

                    return {
                        ...competition,
                        game : gameEntities[competition.game]
                    }
                
                })
            }
        };
    })

export const makeGetInvitations = () => createSelector(
    (state, competitionId) => { 
        const { invitations } = state.competition.byCompetition[competitionId] || { invitations : { didFetch : false, items : null }}
        return invitations;
    },
    (invitations) => invitations
)

export const makeGetUsers = () => createSelector(
    (state, competitionId) => { 
        const { users } = state.competition.byCompetition[competitionId] || { users : { didFetch : false, item : null }}
        return users;
    },
    state => state.entities.users,
    (competitionUsers, userEntities) => {
        return {
            ...competitionUsers,
            item : {
                ...competitionUsers && competitionUsers.item,
                items : (competitionUsers && competitionUsers.item && competitionUsers.item.items || []).map(i => ({
                    ...i,
                    user : userEntities[i.user]
                }))
            }
        }
    }
)


export const getInvitees = createSelector(
    state => state.competition.invitees,
    state => state.entities.users,
    (invitees, userEntities) => {
        return {
            ...invitees,
            items : invitees.items && invitees.items.map(i => ({
                ...i,
                user : userEntities[i.user]
            }))
        }
    }
)




export const getCompetitionHistory = createSelector(
    state => state.competition.competitionHistory,
    (competitionHistory) => {
        const { isFetching, data } = competitionHistory;
        const { count, page, pageSize } = data || { count : 0, page: 1, pageSize : 25 };

        const items = data && data.items ? 
            data.items.map(i => {
                return {
                    ...i,
                    game : {
                        id : i.game,
                        ...data.embedded.games[i.game]
                    }
                }
            }) : [];
        
        return {
            isFetching,
            count,
            page,
            pageSize,            
            items
        }
    }
)



