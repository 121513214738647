import React from 'react'
import styled, { css } from 'styled-components'

const StyledMenu = styled.div`
    font-size: 14px;
`

const StyledHorizontalMenu = styled(StyledMenu)`
    display: flex;
    height: var(--vertical-rythm);
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar { 
        display: none; 
    }

`

const StyledMenuItem = styled.div`
    position: relative;

    text-transform: uppercase;
    letter-spacing: 1px;

    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;

    height: var(--vertical-rythm);

    padding: 0 var(--padding);
    color: var(--link-color, #fff);

    cursor: pointer;

    a, a:hover, a:focus, a:active { color : var(--link-color, #fff) }

    &:hover, &:focus { color : var(--link-color, #fff) }
    

    ${ props => props.active && css`
        &:after {
            position: absolute;
            content: "";

            top: calc(100% - 4px);
            right: 0;
            bottom: 0;
            left: 0;

            
        }`
    }


`

const StyledVerticalMenu = styled(StyledMenu)`
    
    ${ StyledMenuItem } {
        border-top: 1px solid rgba(#fff, 0.1);
        border-bottom: 1px solid rgba(#000, 0.1);
        display: flex;
        justify-content: space-between;
        padding: 0 calc(var(--padding) * 2);
    }
`


export const HorizontalMenu = ({ children }) => {
    return (
        <StyledHorizontalMenu>
                { 
                children
                }
        </StyledHorizontalMenu>
    )
}

HorizontalMenu.defaultProps = {
    themeName : 'default'
}

export const VerticalMenu = ({ children, themeName }) => {
    return (
        <StyledVerticalMenu>
                { 
                    React.Children.map(children, child => {
                        return React.cloneElement(child, { themeName })
                    })
                }
        </StyledVerticalMenu>
    )
}

VerticalMenu.defaultProps = {
    themeName : 'default'
}



export const MenuItem = ({ as, children, ...otherProps }) => {
    return (
        <StyledMenuItem as={ as } {...otherProps}>
            { children }
        </StyledMenuItem>
    )
}

MenuItem.propTypes = {
}

MenuItem.defaultProps = {

}
