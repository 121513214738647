import React, { useRef, useEffect } from 'react'
import useAds from "./useAds";

import * as styles from './AdSlot.module.css';


if (typeof window !== 'undefined') {
	window.googletag = window.googletag || { cmd: [] };
  	googletag.cmd.push(() => {
    	googletag.pubads().disableInitialLoad();
    	googletag.pubads().enableSingleRequest();
    	googletag.enableServices();
  	});
}



export default function AdSlot({ id, adUnit, size, targeting }) {
    const { register, unregister, slots } = useAds();
	const slotId = useRef(id || `slot-${ parseInt(Math.random() * 1000000) }`).current;
	
	useEffect(() => {
		// Register the slot with GPT when the component is loaded.
		googletag.cmd.push(() => {
			
			const slot = googletag.defineSlot(adUnit, size, slotId);

			if (slot) {
		  		slot.addService(googletag.pubads());

				if (targeting) {
					Object.keys(targeting).forEach(k => {
						slot.setTargeting(k, targeting[k]);
					})
				}
		  		googletag.display(slot);
                register(slotId, slot);
			}
			
			
	  	});
  
		// Clean up the slot when the component is unloaded.
	  	return () => {
			const slot = slots[slotId]
			if (slot) {
				googletag.cmd.push(() => {
					googletag.destroySlots([slot]);
				});
				unregister(slotId, slot);
			}
	  	};
	}, []);
  
	// Create the ad slot container.
	return (
		<div className={ styles.adSlot } id={slotId}></div>
	);
  }



  
/**
 * Determine minimum width and height values for an ad slot container
 * based on the configured slot sizes.
 *
 * This function is only provided for example purposes. See
 * [Minimize layout shift](https://developers.google.com/publisher-tag/guides/minimize-layout-shift)
 * to learn more about strategies for sizing ad slot containers.
 */
function getMinimumSlotSize(size) {
	const maxValue = Number.MAX_VALUE;
  
	let minW = Number.MAX_VALUE;
	let minH = Number.MAX_VALUE;
  
	if (Array.isArray(size)) {
	  // Convert googletag.SingleSize to googletag.MultiSize for convenience.
	  const sizes = size.length <= 2 && !Array.isArray(size[0]) ? [size] : size;
  
	  for (const size of sizes) {
		if (Array.isArray(size) && size[0] !== 'fluid') {
		  minW = Math.min(size[0], minW);
		  minH = Math.min(size[1], minH);
		}
	  }
	}
  
	return minW < maxValue && minH < maxValue
	  ? // Static ad slot.
		{ minWidth: `${minW}px`, minHeight: `${minH}px` }
	  : // Fluid ad slot.
		{ minWidth: '50%' };
  }


  /**
 * Determine minimum width and height values for an ad slot container
 * based on the configured slot sizes.
 *
 * This function is only provided for example purposes. See
 * [Minimize layout shift](https://developers.google.com/publisher-tag/guides/minimize-layout-shift)
 * to learn more about strategies for sizing ad slot containers.
 */
function getMaximumSlotSize(size) {
	const minValue = Number.MIN_VALUE;  

	let maxW = Number.MIN_VALUE;
	let maxH = Number.MIN_VALUE;
  
	if (Array.isArray(size)) {
	  // Convert googletag.SingleSize to googletag.MultiSize for convenience.
	  const sizes = size.length <= 2 && !Array.isArray(size[0]) ? [size] : size;
  
	  for (const size of sizes)  {
		if (Array.isArray(size) && size[0] !== 'fluid') {
		  maxW = Math.max(size[0], maxW);
		  maxH = Math.max(size[1], maxH);
		}
	  }
	}
  
	return maxW > minValue && maxH > minValue
	  ? // Static ad slot.
		{ minWidth: `${maxW}px`, minHeight: `${maxH}px` }
	  : // Fluid ad slot.
		{ minWidth: '50%' };
  }

